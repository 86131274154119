#main-left {
  text-align:left;
  ul { margin:0; padding:0; list-style:none; }
  li { margin:0; padding:0; }
  li {
    a {
      display:block; color:color(nav-color);
      padding:12px 10px 12px 20px;
      border-left:4px solid transparent;
      position:relative;

      &:after { content:''; display:none; position:absolute; right:0; top:50%; margin-top:-10px; width:0; height:0; border-top:10px solid transparent; border-bottom:10px solid transparent; border-right:10px solid #fff; }

      i { font-size:18px; line-height:1; margin-right:10px; }
    }

    &.active > a, a:hover {
      color:color(nav-hover);
      background:color(nav-hover-bg);
      border-left-color:color(nav-border);
      &:after { display:block; }
    }
    &.active {
      background:color(nav-hover-bg);
    }
    ul { padding:10px 0; }
    li {
      &.active a, a:hover { &:after { display:none; } }

      a { padding:5px 10px 5px 30px; }

      ul { padding:0; }
      li {
        a { padding-left:40px; }

        &.active > a, a:hover {
          border-left-color:transparent;
        }
      }

    }
  }
}

.small-nav #main-left {
  ul li a span { display:none; }
}