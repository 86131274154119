@function color($name) {
	@return map-get($colours, $name);
}

@mixin transition($property, $duration, $function) {
  -webkit-transition: $property, $duration, $function;
     -moz-transition: $property, $duration, $function;
      -ms-transition: $property, $duration, $function;
       -o-transition: $property, $duration, $function;
          transition: $property, $duration, $function;
}