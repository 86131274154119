.button {
	display:inline-block;
	vertical-align: bottom;
	border-radius: 4px;
	padding:4px 10px;
	background:color(button);
	color:color(text);
	font-size:14px;
	border:0;

	&:hover { background:darken(color(button), $darkenAmount); }
	&:focus { outline:0; }

	&.button-blue {
		background-color:color(blue);
		color:#fff;

		&:hover { background:darken(color(blue), $darkenAmount); }
	}

	&.button-green {
		background-color:color(green);
		color:#fff;

		&:hover { background:darken(color(green), $darkenAmount); }
	}

	&.button-red {
		background-color:color(red);
		color:#fff;

		&:hover { background:darken(color(red), $darkenAmount); }
	}

	&.button-black {
		background-color:#212221;
		color:#fff;

		&:hover { background:lighten(#212221, 10); }
	}
}