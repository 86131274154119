.filter-form {
  border-bottom:1px solid color(top-border);
  padding:10px;
  a.button { padding:8px 17px 7px; }
}

.search {
    background:#fff;
    width:60%;
    position:relative;

    display:inline-block;

    .search-main {
        font-size:0;
        .button, .search-input, label { display:inline-block; }
        .button, input, label { font-size:14px; line-height: 1.4; }
        .search-input { width:75%; }
        input[type="search"] { width:100%; padding:7px; border:1px solid color(border-2); border-right:0; box-sizing:border-box; }
        label { padding:7px 10px; border:1px solid color(border-2); border-width:1px 0 1px 1px; cursor:pointer; width:14%; text-align:center;}
        label i { font-size:90%; margin-left:5px; }
        .button { border:0; cursor:pointer; padding:8px 20px; border-radius:0 4px 4px 0; margin-left:-1px; width:11%; }
    }

    &.active {
        .search-main .button { border-radius:0 4px 0 0; }
        .search-filters { visibility: visible; }
    }


    .search-filters {
        border:1px solid color(border-2);
        border-top:0;
        padding:7px;
        position:absolute;
        width:100%;
        z-index:3000;
        background:#fff;
        visibility: hidden;
    }

    .search-filter-row {
        font-size:0;
        margin:0 0 7px;

        label, .select-holder { display:inline-block; vertical-align: middle; font-size:14px; }
        label { width:30%; padding-right:20px; cursor:pointer; }
        .select-holder { width:70%;  }
        .select-holder select { width:100%; }
        .aa-date-control { 
            border:1px solid color(border); 
            width:34.5%; 
            margin-right:1%; 
            border-radius:3px; 
            padding:5px 5px;
            &:last-child { margin-right:0; }
        }

        &:last-child { margin-bottom:0; }
    }
}

.page-has-loaded .search {
    .search-filters { display:none; }
    &.active { 
        .search-filters { display:block; }
    }
}