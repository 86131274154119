.content-form-row {
  font-size:0; margin:0 0 20px; padding:0 0 10px; border-bottom:1px solid lighten(color(border), 3);
  .form-label, .form-item, .form-control-label { display:inline-block; vertical-align: top; font-size:$systemFontSize; }

  .form-control-label {
    width:200px;
    .form-label { display:block; width:auto; }
    .fa-times { color:color(red); cursor:pointer; }
    .fa-sort { cursor:move; }
    span { margin:0 10px; }
  }
  .form-label { width:200px; font-weight:bold; cursor:pointer;  }
  .form-item {
    width:calc(100% - 200px);
    p { margin:3px 0 0; font-style:italic; font-size:$systemFontSize - 3; }
  }


  .form-control { border:1px solid color(border); border-radius:3px; color:#444; width:100%; max-width:450px; padding:4px 6px; }
  select.form-control { width:auto; }
  textarea.form-control { height:160px; max-width:unset; }

  .form-label-group {
    font-size:$systemFontSize; padding:5px 6px; text-align:center;
    background:color(border); display:inline-block; border-radius:3px 0 0 3px;
    border-right:1px solid darken(color(border), 5); width:auto;
  }
  .with-group-label {
    font-size:0;
    .form-control {
      //width:80%;
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
      border-left:0;
    }
  }

  &.page-banners {
    ul { margin:0; padding:0; list-style:none; }
    li {
      margin:0 0 10px; padding:0; height:80px;
      * { vertical-align: middle; }
      input { margin-right:5px; }
      .fa { font-size:24px; cursor:move; margin-right:15px; }
    }
  }

  #page-meta-description { resize:none; height:85px; }

  .aa-ckfinder-controls {
    margin:0 0 10px;
    .button { margin-right:10px; &:last-child { margin-right:0; }}
  }
  .form-note { margin:0 0 10px; }
}