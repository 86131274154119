.details-control {

  .data-table {
    border:1px solid color(table-border); border-bottom:0;

    .cell-align-right { text-align:right; vertical-align: top; }
    .details-test-cell { width:75%; }
    .details-gst-cell { width:11%; }
    .details-price-cell { width:11%; }
    .details-delete-cell { width:3%; color:color(red); i { cursor:pointer; }}
    .details-add-cell {
      width:3%; color:color(header);
      i { cursor:pointer; }
      &.hide-add-button i { display:none; }
    }
  }

  margin:0 0 20px;

}

.test-details-control, .sample-details-control, .search-details-control, .comments-details-control {
  .data-table {
    .data-table-row:nth-child(2) { display:none; }

    &-row { 
      &:nth-of-type(even) { background-color:color(table-even); }
      &:nth-of-type(odd) { background-color:color(table-odd); }
      &:hover { background-color:lighten(color(header), 35); }
      &:nth-child(1) { background-color:color(table-even); }
    }

  }

  footer { text-align:right; margin-top:10px;  }
}

.test-details-footer {
  display:inline-block;
  .test-details-footer-row {
    text-align:left; 
    width:200px; 
    margin-left:10px; 
    font-size:0; 
    span { display:inline-block; width:50%; font-size:$systemFontSize; font-weight:bold; }
    span.test-details-footer-value { text-align:right; font-weight:normal; }

  }
  
}
