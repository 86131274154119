.tabs-holder {
  .tab-nav {
    background:lighten(color(border), 5);
    padding:20px 20px 0; height:97px;
    aside {
      float:right;
      text-align:right;
      .top-buttons {
        margin:0 0 10px;
        span { margin: 0 5px; vertical-align: top; }
      }
    }

    ul { margin:0; padding:0; list-style:none; }
    li {
      margin:0; padding:3px 18px;
      display:inline-block;
      border-radius:4px 4px 0 0; cursor:pointer;
      border:1px solid transparent; border-bottom:0;
      &:hover { color:color(link-hover); }
      &.active { background:#fff; border-color:color(border); color:color(text); }
      &.no-pointer { cursor:default; }
    }
  }

  &, .tab-panes { height:calc(100% - 49px); }
  .tab-panes {
    border:1px solid color(border); border-top-width:0;
    .tab-pane { display:block; visibility:hidden; }
    .tab-pane.active { display:block; padding:20px; height:100%; overflow:auto; }
  }
  .tab-panes-auto-height {
    .tab-pane.active { height:auto; overflow:visible; }
  }
}

.page-has-loaded .tabs-holder {
  .tab-panes {
    .tab-pane { display:none; visibility: visible; }
    .tab-pane.active { display:block; }
  }
}
