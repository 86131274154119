.form {
	width:80%; 
	 
	.form-row {
		font-size:0; 
		margin:0 0 10px; 
	}

	.form-label { display:block; cursor:pointer; margin:0 0 2px; }
	.form-row-required .form-label:after { content:'*'; font-size:80%; vertical-align: text-top; color:color(red);}
	.form-row-has-error .form-label { color:color(red); }
	.form-control { display:inline-block; border:1px solid color(border); border-radius: 3px; padding:4px 2px; color:color(formInput); &:focus { border-color:color(border-focus)} }
	.form-row-full-multi-width, .form-row-full-width .form-control { width:100%; }
	input { width:40%; }
	input[type="submit"], input[type="checkbox"], input[type="radio"] { width:auto; }
	label { font-size:$systemFontSize; }
	input[type="checkbox"], input[type="radio"] { margin-right:5px; }
	textarea { resize:none; width:100%; height:160px; }
	.form-label-group { font-size:$systemFontSize; width:20px; padding:5px 0; text-align:center; background:color(border); display:inline-block; border-radius:3px 0 0 3px; }

	#form_active, .form-yes-no { width:60px; } 
	#form_user_level { width:140px; } 
	#form_categories { width:100%; }
	#form_product_category_id { width:100%; }
	#form_groups { width:230px; }
	#form_field_type { width:230px; }
	#form_price { width:140px; }
	.media-holder { font-size:$systemFontSize; }

    .form-row-inner-row { margin-bottom:4px; }
    .after-input-note { font-size:14px; margin-left:4px; }
    .duplicate-note { font-size:14px; }
    .form-button-row .button { margin-bottom:5px; }

	.form-group {

		font-size:0; 
		.form-row { display:inline-block; vertical-align: top; margin-right:2%; }
		input { width:100%; }

		&.form-group-1 .form-row {
			width: 100%;
			margin-right:0; 
		}

		&.form-group-2 .form-row {
			width: 49%;

			&:nth-child(2n+2) { margin-right:0; }
		}

		&.form-group-3 .form-row {
			width: 32%;

			&:nth-child(3n+3) { margin-right:0; }
		}

		&.form-group-4 .form-row {
			width: 23.49999999%; 
 
			&:nth-child(4n+4) { margin-right:0; }
		}

		&.form-group-5 .form-row {
			width: 18.4%;

			&:nth-child(5n+5) { margin-right:0; }
		}

		.with-group-label .form-control {
			width:80%; 
			border-top-left-radius: 0;
			border-bottom-left-radius: 0;
		}

		&.form-group-with-data-table {
		  h3 { margin-bottom:5px; }
		  font-size:$systemFontSize;
		  .data-table {
		    border:1px solid color(table-border); border-bottom:0;
            width:80%; margin:0 0 20px;
          }
		}

		.form-row-two-up {
		  .form-row { width:49%; }
		  .form-row:nth-child(2n+2) { margin-right:0; }
		}
	}

	.form-note { font-size:12px; font-style: italic; padding-top:2px; }
	.after-field-note { font-size:$systemFontSize; font-style:italic; padding-top:2px; }

	.form-fieldset {
		h5 { font-size:18px; font-weight:normal; margin:0 0 4px; border-bottom:1px solid color(border); padding-bottom:4px; }
	}

	/*.aa-multi-select-columns, .aa-multi-select-merchants, .aa-multi-select-tests,
	.aa-multi-select-samples, .aa-multi-select-search, .aa-multi-select-comments { width:690px; }
	.aa-multi-select-samplers { width:340px;  }*/

	.sender-contact-details {
	  font-size:$systemFontSize;
	  visibility:hidden;
	}

	#form_comments_comments { height:83px; }

	.tab-panes .form-label, .tab-panes label { font-weight:bold; font-size:15px; }

	.form-date-time-date, .form-date-time-time { 
		display:inline-block; vertical-align: top; 
	}
	.form-date-time-date { width:100px; margin-right:6px; }
	.form-date-time-time {
		select { margin-right:6px; &:last-child { margin-right:0; } }
	} 

}

.aa-checkbox-label { display:inline-block; vertical-align: middle; cursor:pointer; }
.aa-checkbox-input { display:none; }
.aa-checkbox-label:before { content:''; width:25px; height:25px; line-height:25px; border-radius:3px; background:#fff; border:1px solid color(border); display:inline-block; vertical-align: middle; font-family:FontAwesome; font-size:14px; text-align:center; }
.aa-checkbox-input:checked + label:before { content:'\f00c'; }
.aa-checkbox-input:disabled + label:before { background:#e5e5e5; cursor:default; }

.alert {
	padding:20px;
	margin:0 0 20px;
	border:1px solid color(border-2);
	border-radius:3px; 
	border-left:5px solid color(red);

	h4 { color:color(red); }
}

.alert-success {
  border-left-color:color(green);
}

.note {
	padding:5px;
	margin:0 0 5px;
	color:#fff;
	background:color(green2);

	&.has-error { background:color(red) }
}

.form-submission {
  border-top:1px solid color(table-border);
  border-left:1px solid color(table-border);
  th, td {
    padding:10px 12px; text-align:left;
    border-bottom:1px solid color(table-border); border-right:1px solid color(table-border);
  }
  tr {
    &:nth-of-type(odd) th, &:nth-of-type(odd) td { background-color:color(table-even); }
    &:nth-of-type(even) th, &:nth-of-type(even) td { background-color:color(table-odd); }
    &:hover th, &:hover td { background-color:lighten(color(header), 35); }
  }
}