.paging-holder {
	/*background:#fff;
	position:fixed;
	width:$content; 
	left:$leftColumn;*/
	bottom:0; right:0; 
	padding:4px 6px;
	/*border-top:1px solid color(border);*/

	ul { 
		margin:0; padding:0; list-style:none; text-align:right; font-size:0; 
		
		li { 
			display:inline-block; font-size:$systemFontSize;
			margin-left:5px; 
			
			a, span { display:block; padding:2px 7px; border-radius:3px; }
			&.active span { background:color(border); }
		}
	}

	.paging-count { float:left; padding:2px 0; }
}