.tree-data-holder {
  position:relative; height:calc(100% - 54px);

  .tree-data { width:80%; height:100%; position:absolute; top:0; left:20%; border-left:1px solid color(border); }

  .tree {
    width:20%; padding:20px;  overflow:auto;

    ul {
      margin:0; padding:0; list-style:none;
      ul { margin:0 0 0 20px; display:none; }
    }

    li {
      margin:0 0 3px; padding:0;

      &.active {
        > ul { display:block; }
      }


      span.active { color:color(link); }
      span.page-disactive { font-style:italic; text-decoration: line-through; }


      &.has-children > i {
        position:relative;
        &:after {
          content:'+'; position:absolute; left:-15px;
        }
      }
      &.active.has-children > i:after {
        content:'-'; top:-2px; left:-13px;
      }

      span { cursor:pointer; &:hover { color:color(link); }}
      i { cursor:pointer; margin-right:4px; }
    }
  }
  &.tree-data-ckeditor .tree { width:100%; }
}

.tree-tab-holder {
  .tree-tabs {
    background:lighten(color(border), 5);
    padding:20px 20px 0; height:97px;
    aside {
      float:right;
      text-align:right;
      .top-buttons {
        margin:0 0 10px;
        span { margin: 0 5px; vertical-align: top; }
      }
    }

    ul { margin:0; padding:0; list-style:none; }
    li {
      margin:0; padding:3px 18px;
      display:inline-block;
      border-radius:4px 4px 0 0; cursor:pointer;
      border:1px solid transparent; border-bottom:0;
      &:hover { color:color(link-hover); }
      &.active { background:#fff; border-color:color(border); color:color(text); }
      &.no-pointer {
        cursor:default;
        &:hover { color:color(text); }
      }
    }

    .tree-view-switcher {
      float:right;
      i { cursor:pointer; margin-left:10px; }
      &.view-mode-list i.fa-th-list { color:color(blue); }
      &.view-mode-grid i.fa-th-large { color:color(blue); }
    }
  }

  &, .tree-tab-panes { height:calc(100% - 49px); }
  .tree-tab-panes {
    .tree-tab-pane { display:none; }
    .tree-tab-pane.active { display:block; padding:20px; height:100%; overflow:auto; }
  }
}
