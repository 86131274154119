.dropdown {
   max-width:250px; margin:0;
   font-size:$systemFontSize;

   dd,  dt,  ul { margin:0; padding:0; }
   dd { position:relative; }

   dt a { background:#fff; display:block; cursor:default; border:1px solid #e5e5e5; font-weight:normal; padding:5px; position:relative; text-decoration:none; color:#444; border-radius:3px; }
   dt a:after { content:'\f0d7'; font-family:FontAwesome; position:absolute; right:5px; top:5px;  }
   dt a:hover { border-color:#7eb4ea; text-decoration: none;  }

  &-toggle { visibility:hidden; background:#fff; list-style:none; padding:0; position:absolute; left:0; top:0; width:100%; border:1px solid #444; border-color:#b2b2b2 #b2b2b2 #b2b2b2 #b2b2b2; max-height:250px; overflow:auto; margin-bottom:20px; }
   dd ul li { padding:5px; display:block; color:#444; text-decoration:none; }
   dd ul li { padding-left:15px; }
   dd ul li:hover,  dd ul li.active { background:#3399ff; color:#fff; text-decoration: none; }

   .fa { width:18px; }
}
