/* grid system */
.holder { position:relative; max-width:980px; margin:0 auto; }
img { max-width:100%; }

.hide-mobile { display:block; }
.show-mobile, .column.show-mobile { display:none; }

.row { letter-spacing: -0.31em; *letter-spacing: normal; *word-spacing: -0.43em; text-rendering: optimizespeed; position:relative; }
.column { display:inline-block; *display:inline; *zoom:1; vertical-align: top; letter-spacing: normal; word-spacing: normal; text-rendering: auto; min-height:1px; }

    /* columns */
    .row-with-gutter {
      .column { margin-left:2%; }
      .column:first-child { margin-left:0; }

      .column-1   { width: 6.5%; }
      .column-2   { width: 15%; }
      .column-3   { width: 23.5%; }
      .column-4   { width: 32%; }
      .column-5   { width: 40.5%; }
      .column-6   { width: 49%; }
      .column-7   { width: 57.5%; }
      .column-8   { width: 66%; }
      .column-9   { width: 74.5% }
      .column-10  { width: 83%; }
      .column-11  { width: 91.5%; }
      .column-12  { width: 100%; margin-left: 0; }
    }

    .column-1   { width: 8.333333333333%; }
    .column-2   { width: 16.66666666667%; }
    .column-3   { width: 25%; }
    .column-4   { width: 33.33333333333%; }
    .column-5   { width: 41.66666666667%; }
    .column-6   { width: 50%; }
    .column-7   { width: 58.33333333333%; }
    .column-8   { width: 66.66666666667%; }
    .column-9   { width: 75% }
    .column-10  { width: 83.33333333333%; }
    .column-11  { width: 91.66666666667%; }
    .column-12  { width: 100%; margin-left: 0; }
    /* end columns */

    /* offsets */
    .row .offset-1   { margin-left: 8.5%; }
    .row .offset-2   { margin-left: 17%; }
    .row .offset-3   { margin-left: 25.5%; }
    .row .offset-4   { margin-left: 34%; }
    .row .offset-5   { margin-left: 42.5%; }
    .row .offset-6   { margin-left: 51%; }
    .row .offset-7   { margin-left: 59.5%; }
    .row .offset-8   { margin-left: 68%; }
    .row .offset-9   { margin-left: 76.5%; }
    .row .offset-10  { margin-left: 85%; }
    .row .offset-11  { margin-left: 93.5%; }
    /* end offsets */

    @media(max-width:876px) {
        .row .column-md { width:100%; margin:0; display:block; }

        .row .column-md.hide-mobile { display:none; }
        .row .column-md.show-mobile { display:block; }
    }

    @media(max-width:660px) {
        .row .column { width:100%; margin:0; display:block; }

        .hide-mobile, .row .hide-mobile { display:none; }
        .show-mobile, .row .show-mobile { display:block; }
    }

/* end grid system */