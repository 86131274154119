$colours: (
        purple                  : #524161,
        green                   : #b2d138,
        red                     : #e83d5f,
        red2                    : #f33232,
        blue                    : #377bc1,
        green2                  : #45dc81,
        black                   : #121212,

        header                  : #45c8dc,
        other-header            : #74c044,
        main-left               : #262a33,

        text                    : #8b8b8b,
        link                    : #377bc1,
        link-hover              : darken(#377bc1, 20),
        nav-color               : #969ca9,
        nav-hover               : #fff,
        nav-hover-bg            : #1d2027,
        nav-border              : #43c4d7,
        nav-icon                : #777,
        border                  : #e5e5e5,
        border-focus            : #45c8dc,
        border-2                : #d8d8d8,

        table-even              : darken(#fff, 3),
        table-odd               : #ffffff,
        table-border            : #e1e1e1,
        table-hover             : lighten(#377bc1, 45),

        top-border              : #e4e4e4,

        button                  : #eee,
        formInput               : #444,

        chosenArrow             : #9d9d9d
) !default;

$systemBackground: #fff;
$bodyBackground: #fff;
$systemFontSize: 16px;
$systemLineHeight: 1.5;
$systemFont: 'Open Sans', Arial, Helvetica, Verdana, sans-serif;

$h1: 26px;

$brighten: 3;
$leftColumn: 210px;
$leftColumnSmall: 77px;
$content: 100%;
$darkenAmount: 8;
