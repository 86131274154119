.settings-controls {
  .form { width:100%; }
  .settings-form-control {
    border-bottom:1px solid color(border);
    margin:0 0 20px; padding:0 0 10px;

    .form-group {
      margin:10px 0 0; position:relative;
      .button { margin-top:5px; text-align:right; /*position:absolute; right:0; bottom:14px; */ }
    }

  }
  .settings-field-remove {
    float:right;
    i { cursor:pointer; color:color(red); font-size:$systemFontSize; }
  }
}
