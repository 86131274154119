.history-holder {
	margin-top:40px; 
	h3 { 
		margin:0 0 10px; 
		cursor:pointer;

		.fa-angle-up { display:none; }
		&.active {
			.fa-angle-up { display:inline-block; }
			.fa-angle-down { display:none; }
		}
	}

	.history-data-table { display:none; }

	.data-table { 
		border:1px solid color(border); font-size:14px; border-bottom:0; 
		.data-table-cell { padding:5px 6px; }
	}
}