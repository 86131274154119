.template-login {
	&, body {
		background:#f4f4f4; 		
	}
}

.login-holder {
  width:400px; margin:20px auto 0;
  border:1px solid darken(#f4f4f4, 10);
  padding:20px; border-radius:5px; background:#fff;


  .form { width:100%; }
  .button { font-size:$systemFontSize; }

  a { float:right; font-size:$systemFontSize;  }
  .plain-input { font-size:$systemFontSize; border:0; padding:0; }
}