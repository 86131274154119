.media-library {
  height:calc(100% - 54px);
  .tree-data-holder { height:100%; }
  &-item-image > span {
    background:#fff no-repeat center center / contain;
    display:block; width:100%;
    img { display:block; width:100%; visibility: hidden; }
  }
  &-item-title {
    font-size:16px; font-weight:bold; color:color(blue);
  }

  &-loading {
    text-align:center; padding:20px; font-size:20px; line-height:1;
    i { margin-left:8px; }
  }

  &-grid {
    .file-listing {
      display:flex; flex-flow:row wrap; //justify-content:space-between;
      //&:after { content:''; flex:auto; }
      figure {
        padding:5px; margin:0 2% 25px 0; cursor:pointer;
        width:15%;
        font-size:14px; line-height:1.3; background:color(table-even); border:1px solid color(border);
        &:nth-child(6n+6) { margin-right:0; }
        .media-library-item-image { margin:0 0 10px; }
        figcaption {
          padding:5px 5px 10px; text-align:center;
        }

        .media-library-item-title {
          display:block; width:100%; margin:0 0 5px;
          overflow:hidden; white-space:nowrap; text-overflow:ellipsis;
        }

        .media-library-item-type:after { content:' / '; }

        &:hover { background:color(table-hover); }
      }
    }
  }

  &-list {
    header {
      display:flex; flex-direction:row; border:1px solid darken(color(header), 10); border-width:1px 1px 0;
      background:color(header); color:#fff;
      > span {
        &:first-child {
          flex-grow:0; flex-shrink: 0;
          span { display:block; width:90px; padding:5px; }
        }
        &:last-child {
          flex:4; display:flex;
          > span {
            flex:1; border-left:1px solid darken(color(header), 10); padding:5px;
            &:first-child { flex:4; padding-left:10px; }
          }
        }
      }
    }

    figure {
      display:flex; flex-direction:row; border:1px solid color(border); border-width:0 1px; cursor:pointer;
      &:nth-child(2n+2) {
        background:color(table-even);
        .media-library-item-image span { background-color:#fff; }
      }
      &:last-child { border-bottom-width:1px; }
      &:hover { background:color(table-hover); }

      .media-library-item-image {
        flex-grow:0; flex-shrink:0; padding:5px;
        img { width:80px; }
        span { display:block; background-color:color(table-even); }
      }
      figcaption {
        display:flex; flex:4;
        > span {
          flex:1; align-items:center; display:flex;
          padding:5px; height:100%; border-left:1px solid color(border);
        }
        .media-library-item-title {
          flex:4;
          padding-left:10px;
        }
      }

    }
  }

  &-view {
    display:flex; flex-direction:row;
    figure {
      flex:2; background:color(table-even);
      > span {
        display:block; width:100%; padding:10px; position:relative;
        img { width:100%; display:block; }
        a {
          position:absolute; bottom:10px; padding:5px 15px; cursor:pointer;
          display:block; background:color(other-header); color:#fff;
          &:hover { background:darken(color(other-header), 10); }
          &:first-child { left:10px; }
          &:last-child { right:10px; }
        }
      }
      figcaption {
        padding:0 10px 10px; text-align:center;
        .media-library-item {
          &-title { display:block; }
          &-type { margin-right:10px; }
        }
      }
    }
    aside {
      flex:3;
      .media-library-view-details {
        padding:0 20px;
        .media-library-view-details-row {
          margin:0 0 10px;
          input, textarea { border:1px solid color(border); width:100%; padding:3px; }
          textarea { resize:none; height:140px; }
          &.media-library-view-details-button-row {
            text-align:right;
          }
        }
      }
    }
  }

  &-fade-transition {
    transition: opacity 1.5s ease;
  }
  &-fade-enter {
    opacity: 0;
  }
  &-fade-leave {
    opacity: 0;
    position: absolute; /* important for removal move to work */
  }
  &-fade-move {
    transition: transform 1.5s cubic-bezier(.55,0,.1,1); /* applied when moving */
  }


  &-file-uploader {
    position:fixed; z-index:300; width:100%; height:100%; left:0; top:0; background:rgba(0,0,0,0.4);
    padding:60px 0 60px $leftColumn; display:none;
    &.show-uploader { display:block; }
    &-inner {
      padding:15px; width:80%; height:100%; margin:0 auto; background:#fff;
      box-shadow:0 0 28px -3px rgba(0, 0, 0, 0.5);
      &.has-files { height:100%; }
      > header {
        margin-bottom:10px; position:relative;
        h3 { margin:0; }
        i { position:absolute; right:0; top:5px; cursor:pointer; color:color(red); &:hover { color:color(black);}}
      }
    }

    .media-library-upload-drop-zone {
      outline:0; padding:10px; border:1px solid #000;
      text-align:center;
      span { display:none; }
    }

    &.is-advanced {
      .media-library-upload-drop-zone {
        background-color: #fff; border: 2px dashed #000;
        padding:40px 20px;

        span { display:block; margin-bottom:15px; }
      }
    }

    .is-dragover .media-library-upload-drop-zone {
      background:lighten(color(header), 30);
    }

    .media-library-upload-files-input {
      input { opacity:0; display:none; visibility:hidden; }
      .button { cursor:pointer; }
    }

    footer {
      margin-top:10px;
      a:last-child { float:right; }
    }
  }

  &-files-to-upload-list {
    height:calc(100% - 278px);
    &.is-uploading { height:calc(100% - 74px); }
  }

  &-files-to-upload-holder {
    margin-top:10px; height:100%;
    header {
      display:flex; flex-direction:row; border:1px solid darken(color(header), 10); border-width:1px 1px 0;
      background:color(header); color:#fff; position:fixed;
      > span {
        &:first-child {
          flex-grow:0; flex-shrink: 0;
          span { display:block; width:90px; padding:5px; }
        }
        &:last-child {
          flex:4; display:flex;
          > span {
            flex:1; border-left:1px solid darken(color(header), 10); padding:5px;
            &:first-child { flex:4; padding-left:10px; }
            &:last-child {
              flex-grow:0; flex-shrink:0;
              span { width:30px; display:block; }
            }
          }
        }
      }
    }
  }

  &-files-to-upload {
    height:100%; overflow:auto;
    > div { padding-top:35px;}
    figure {
      display:flex; flex-direction:row; border:1px solid color(border); border-width:0 1px 1px;
      &:nth-child(2n+2) {
        background:color(table-even);
        .media-library-item-image span { background-color:#fff; }
      }
      &:hover { background:color(table-hover); }

      .media-library-item-image {
        flex-grow:0; flex-shrink:0; padding:5px;
        img { width:80px; }
        span { display:block; background-color:color(table-even); }
      }
      figcaption {
        display:flex; flex:4;
        > span {
          flex:1; align-items:center; display:flex;
          padding:5px; height:100%; border-left:1px solid color(border);
        }
        .media-library-item-title {
          flex:4; padding-left:10px;
          flex-direction:column; justify-content: center; align-items: flex-start;
          .media-library-error { color:color(red); font-size:13px; line-height:1.2; font-style:italic; }
        }
        .media-library-item-remove {
          flex-grow:0; flex-shrink:0;
          span {
            display:block; width:30px; text-align:center;
            i { cursor:pointer; color:color(red); }
          }
        }
      }
    }
  }

  &-progress-bar-holder {
    height:20px; width:50%; border:2px solid darken(color(header), 10); display:block; margin-top:5px;
    > span { display:block; background:color(header); width:50%; height:100%; }
  }
}