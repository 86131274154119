/* system */
html { background:$systemBackground; }
body { padding:0; background:$bodyBackground; }
body, button, input, select, textarea { font:#{$systemFontSize}/#{$systemLineHeight} $systemFont; color:color(text); -webkit-font-smoothing: antialiased; }
html, body { height:100%; }
/* end system */

/* type */
h1, h2, h3, h4, h5, h6 { }
h1 { font-size:$h1; line-height:$systemLineHeight; }
h2 { margin:0 0 10px; font-weight:400; }
h3 { }
h4 { }
h5 { }

a { color:color(link); text-decoration:none; }
a:hover { color:color(link); }

.content-block { padding:10px; }
a, button, input[type="submit"], .select2-selection__choice__remove, .tree span { @include transition(all, 300ms, linear) }
.clearfix:after {  content: ""; display: table; clear: both; }
#site{ height:100%; }
#header, #body { position:fixed; width:100%; }
#header { top:0; left:0; }

.column-content { font-size:0; }
.left-column, .right-column { display:inline-block; vertical-align: top; font-size:$systemFontSize; }
.left-column { width:$leftColumn; }
.right-column { width: -moz-calc(#{$content} - #{$leftColumn}); width: -webkit-calc(#{$content} - #{$leftColumn}); width: -o-calc(#{$content} - #{$leftColumn}); width: calc(#{$content} - #{$leftColumn}); }

.view-all { padding:10px; text-align:right; border-bottom:1px solid color(table-border)}

#body { background:#fff; z-index:40; }
#main-left, #main-content { position:fixed; bottom:0; top:56px; overflow:auto; }
#main-left { padding:20px 0; background:color(main-left); }
#main-content { left:$leftColumn; z-index:10; }
.small-nav { 
	#main-content { 
		left: $leftColumnSmall; 
		width: -moz-calc(#{$content} - #{$leftColumnSmall});
		width: -webkit-calc(#{$content} - #{$leftColumnSmall});
		width: -o-calc(#{$content} - #{$leftColumnSmall});
		width: calc(#{$content} - #{$leftColumnSmall});
	}
	#main-left { width:$leftColumnSmall; }
}

.body-content { padding:20px; }



#logo { padding:10px 10px 7px; background:#fff; border-bottom:1px solid #262a33; }
#logo img { display:block; }
#header { background:color(other-header); }
#main-header { 	padding:10px; vertical-align: middle; color:#fff; z-index:30; }

.header-account { 
	float:right; color:#fff; font-size:14px; line-height:1.3; 
	padding-top:8px; 
	a { color:#fff; &:hover { color:#000;  } }
}


.button-controls-row {
  border-bottom:1px solid color(top-border); padding:10px; width:100%;
  h1 { margin:0; font-size:22px; line-height:$systemLineHeight; }

  aside { float:right; * { vertical-align:top; } }
}


#main-header {
	z-index:200; color:color(text);
	input[type="search"] { border:0; padding:4px 5px; width:350px; }
	input[type="submit"] { margin-left:3px; padding:6px 10px; vertical-align: top; }
	.chosen-container-single .chosen-single { border:0; border-radius: 0;}
	.chosen-container { vertical-align: top; border:1px solid color(border); }
	.chosen-container-single .chosen-single span { padding-left:4px; }

	.search { border-radius:0 3px 3px 0; }
	.search .search-main .button { margin-left:0; }

	.filter-form { 
		padding:0; border:0; 
		.search-filter-label { background:#fff; }
		z-index:1000; 

		a.button { 
			background:#000; 
			padding-top:7px; 
			&:hover { background:lighten(#000, 8) } 
		}

	}

}

.loader {
  position:fixed; left:0; top:0; z-index:3000; background:rgba(0,0,0,0.5); width:100%; height:100%; text-align:center;
  display:none;
  &:before { content:''; height:100%; display:inline-block; vertical-align: middle; }
  i { color:#fff; font-size:30px; vertical-align: middle; }
}

.align-right { text-align:right; }


.inner-header {
  width:100%; padding:10px; border-bottom:1px solid color(table-border);
  h2 { margin:0; }
  aside {
    float:right; border:0;
    select { border:1px solid color(border); padding:3px; border-radius:4px; }
  }
}
