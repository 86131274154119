.data-table {
	display:table; width:100%;
	&-row { 
		display:table-row; width:100%;
		&:nth-of-type(odd) { background-color:color(table-even); }
		&:nth-of-type(even) { background-color:color(table-odd); }
		&:hover { background-color:lighten(color(header), 35); }
	}

	&-head { 
		display:table-header-group;
		&:hover { background-color:color(table-even); }
		a { 
			color:color(text); 
			i { margin-left:4px; color:color(teal); }
		}
	}

	&-cell {
		display:table-cell; border-bottom:1px solid color(table-border); padding:10px 12px;
		border-right:1px solid color(table-border); min-width:20px; vertical-align: top;
		&:last-child { border-right:0; }

		&.data-table-controls { width:60px; text-align:right; padding-right:10px; padding-left:8px; }
		&.data-table-controls-3 { width:93px; }
		&.data-table-controls-activate { width:148px; }
		&.data-active { width:90px; text-align:right; }
		&.data-sort { width:26px; text-align:center; cursor:move; }
		&.data-cell-id { width:52px; max-width:60px; }
		&.data-cell-code { width:80px; }
		&.data-cell-shortcode { width:190px; }
		&.cell-align-right { text-align:right; }
		&.data-history-date-cell { width:190px; }
		&.data-country-location { width:250px; }
		&.data-table-value-cell { width:120px; }

		&.details-add-cell-2 { width:30px; text-align:right; color:color(header); i { cursor:pointer; } }
		&.details-delete-cell-2 { text-align:right; color:color(red); i { cursor:pointer; } }
	}

	.data-table-row-disactive .data-active { color:color(red); font-style:italic; }

	form {
		display:inline-block; margin-left:10px; 
		button { margin:0; padding:0; background:none; cursor:pointer; border:0; color:color(red);}
	}
	.data-table-controls a:nth-child(2) { margin-left:10px; }

}

@media screen and (max-width: 660px) {
	.data-table, .data-table-head, .data-table-row, .data-table-cell { display:block; }
    .data-table-head { display:none; }
    .data-table-cell:before { font-weight:bold; margin-right:10px; content:attr(data-label) ':';}
}

.listing-buttons { padding:4px 6px; }